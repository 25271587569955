import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Madman from './Pages/Madman';

const Router =()=> (
    <BrowserRouter>
    <Routes>
    {/* <Route path="/" element={<App />} /> */}
    <Route path="/" element={<Madman />} />

  
    </Routes> 
    </BrowserRouter>
 
)

export default Router;
