import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './Routes';
import { Provider } from 'react-redux'
import store from './redux-store/store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <Routes />
    <ToastContainer
  position="top-center"
  autoClose={3000}
  hideProgressBar={true}
  closeOnClick={true}
  pauseOnHover={true}
  limit={1}
  style={{
    fontSize: "1rem",
    borderRadius: "1rem",
    padding: "1rem",
    zIndex: 101,
    pointerEvents: "auto"
  }}
  toastStyle={{
    fontSize: "1rem",
    color: "black",
    fontWeight: "bold",
    background: "#cce5ff",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    pointerEvents: "none"
  }}
  progressClassName={{
    background: "black"
  }}
  newestOnTop={true}
/>

</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
