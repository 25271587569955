import React,{useState, useEffect, useRef} from 'react';
import { Configuration, OpenAIApi } from "openai";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './Madman.css';

function Madman() {
    const [rippleStyle, setRippleStyle] = useState({});
    const [isRippling, setIsRippling] = useState({});
    const fullTextA = "Hello my name is Don. I'm the AI Creative Director of YH8. How can I help you?";
    const [displayText, setDisplayText] = useState('');
    const [displayOutput, setDisplayOutput] = useState('');

    const [bgImage, setBgImage] = useState('home-1');
    const [inputText, setInputText] = useState('');
    // const [playMusic, setPlayMusic] =useState(true);   
    const [status, setStatus] = useState('stop');
    const [response, setResponse] = useState('');
    const [soundStatus, setSoundStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const typingDelay = 100; // delay in milliseconds
    const TextSound = new Audio('/text-sound.mp3');
    TextSound.volume = 0.35; // Set volume to 50%

    const musicRef = useRef(new Audio('/music.mp3'));
    const [chatLLM, setChatLLM] = useState([]); // Stores chat messages
    const navigatTo = useNavigate();
    const sound = new Audio('/sound.mp3');
    const [typing,setTyping ] = useState(false);
    const [fullText, setStartText] = useState(fullTextA);
    const [info, setInfo] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {

      setWidth(window.innerWidth);
    };
    useEffect(async() => {

      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    async function onClickInfo() {
      setInfo(!info)
     
    }

  useEffect(()=> {
    if(!info) {
      setDisplayText('')
     setStartText(fullTextA)
    } else {
      setDisplayText('')
      setStartText(`Credits:
      8-Bit sound effects from Pixabay.com
      Pixel artwork and music by YH8.ca`);
    }
   },[info])
   
    function handleCopyClick() {
      const copyText = chatLLM[chatLLM?.length - 1];
      if (!copyText) {
        toast.error("Ask bot to write before sharing!");
        return;
      }
    
      // Modern approach with Clipboard API
      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyText).then(() => {
          toast.success("Copied to clipboard!");
        }).catch(err => {
          console.error("Error copying text: ", err);
          toast.error("Failed to copy!");
        });
      } else {
        // Fallback using execCommand
        try {
          const textarea = document.createElement('textarea');
          textarea.value = copyText;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          toast.success("Copied to clipboard!");
        } catch (err) {
          console.error("Fallback Error copying text: ", err);
          toast.error("Failed to copy!");
        }
      }
    }
    
      const handleKeyPress = (event) => {
        if (inputText.trim()) {
          if (event.key === 'Enter') {
            event.preventDefault();
          
            generateResponse();
          }
        } else {
          return;
        }
       
        
      };

    const configuration = new Configuration({
        apiKey: "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",
      });
    const openai = new OpenAIApi(configuration);
    const generateResponse = async () => {
        setResponse('');
        if (inputText.trim()) {
          try {
            setLoader(true);
            
            // Concise prompt for the AI
            const content = `You are Madmen, a succinct bot that responds in few words. As Madmen, a succinct bot, summarize a business concept, craft a humorous slogan, and create three ad slogans. Here is your previous response and if empty then ignore it ${chatLLM[chatLLM?.length-1]}. Utilize 70 max tokens`;
            const result = await openai.createChatCompletion({
              model: "gpt-4",
              temperature: 0.7, // Lower for more deterministic outputs
              messages: [{ role: "system", content:content }, 
                         { role: "user", content: inputText }],
            });
            setResponse(result?.data?.choices[0]?.message?.content);
            setChatLLM(prevChatLLM => [...prevChatLLM, result?.data?.choices[0]?.message?.content]);
            setInputText('');
          } catch (error) {
            console.error(error);
            toast.error("An error occurred while generating the response.");
          } finally {
            setLoader(false);
          }
        } else {
          toast.error("Please enter your query.");
        }
      };
      


    useEffect(() => {
        // Get the music from the ref
        const music = musicRef.current;
        // Set the music to loop
        music.loop = true;
    
        // Function to play music
        const play = () => {
          // Play the music if playMusic is true
          if (soundStatus) {
            music.play().catch(e => console.log('Error playing music:', e));
          }
        };
    
        // Function to pause music
        const pause = () => {
          music.pause();
        };
    
        // Subscribe to the "ended" event
        music.addEventListener('ended', play);
    
        // Play or pause music based on playMusic state
        if (soundStatus) {
          play();
        } else {
          pause();
        }
    
        // Cleanup function
        return () => {
          pause();
          // Remove event listener to prevent memory leaks
          music.removeEventListener('ended', play);
        };
      }, [soundStatus]); // Re-run the effect when playMusic changes
    

      
    useEffect(() => {
      if (displayText.length < fullText.length) {
        if(soundStatus) {
          // TextSound.play();

        }

        setTimeout(() => {
          setDisplayText(fullText.substring(0, displayText.length + 1));
        }, typingDelay);
      }
    }, [displayText, fullText, info]);

    useEffect(() => {
      if (displayOutput.length < response.length) {
        if(soundStatus) {
          TextSound.play();
        }
    
        // Schedule the next character to be added to displayOutput
        const timer = setTimeout(() => {
          setDisplayOutput(response.substring(0, displayOutput.length + 1));
        }, typingDelay);
    
        // Cleanup the timeout when the component unmounts or updates
        return () => clearTimeout(timer);
      } else {
        // When the full text has been displayed, stop the sound
        TextSound.pause();
        TextSound.currentTime = 0; // Reset the sound to start
      }
    }, [displayOutput, response, soundStatus, typingDelay]);
    
 
  const handleButtonClick = (buttonName, event) => {
    if(soundStatus) {
    sound.play();
    }
    console.log(`${buttonName} button clicked!`);
    // Play the sound
    if(buttonName === 'Start') {
        setBgImage('home-2')
        setStatus('started')
        setResponse('');
        if(inputText) {
            generateResponse()
        }

    } else if (buttonName === 'Cancel') {
        setBgImage('home-1')
        window.location.reload();
    } else if (buttonName === 'Share') {
        handleCopyClick()
    } else if (buttonName === 'Donate') {
      window.open('https://digital.yh8.ca/?utm_source=referral+&utm_medium=aibot&utm_campaign=YH8_Redirect_AIBOT')
    }
    setIsRippling(prev => ({ ...prev, [buttonName]: true }));

    setTimeout(() => {
      setIsRippling(prev => ({ ...prev, [buttonName]: false }));
    }, 100); // This should match the duration of your ripple effect

    // Implement the functionality for each button click here
  };
  const containerStyle = {
    width: '100%', // Takes up the full width
    height: '100vh', // Full screen height by default
    backgroundImage: `url(/${bgImage}.png)`,
    backgroundSize: width>600?'contain':"cover",
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // Media query for devices with a width of up to 600px
    '@media (max-width: 600px)': {
      height: '100vh', // Adjust height to account for mobile browser UI
      padding: '10px', // Add some padding on smaller screens
      
    },
  };

  return (
    <div  
      style={containerStyle}
      >
  
  {
    status==='stop'?
    <div style={{ display: 'flex',  paddingTop:width>600?'':"15rem",flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
  <div style={{
      backgroundImage:  width<=600 ?"":`url(/chatbox.png)`,
      backgroundSize: 'contain',
     

      backgroundRepeat: 'no-repeat',
      width: width<=600 ? '200px' : '800px',
      height: width<=600 ? '170px' : '188px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}>
      <p style={{
        margin: 0,
        position: 'absolute',
        color: 'black',
        background: width<=600 ?"white":"",
        border:  width<=600 ?'3px solid black' :"",
        padding: '0.5rem',
        fontFamily: 'MyCustomFont',
        textAlign: 'center',
        fontSize: width<=600 ? '10px' : '23px',
        marginTop: width<=600 ? '8rem' : '-1.4rem',
        marginBottom: "0.5rem"
      }}>
        {/* {displayText} */}
        {info?<a   href='https://digital.yh8.ca/#ai-disclaimer'
  target='_blank' style={{marginLeft:"1rem", cursor:"pointer",textDecoration:"none", color:"black"}}>
          Credits:
      8-Bit sound effects from Pixabay.com
      Pixel artwork and music by YH8.ca Copyright 2024 YH8. Disclaimer
        </a>:"Hello my name is Don. I'm the AI Creative Director of YH8. How can I help you?"}
      </p>
    </div>
    <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
 textAlign:"center",
 marginLeft: width>600?'17rem':"1rem",
 
}}>
  <div style={{
    backgroundImage: `url('/don.gif')`,
    backgroundSize: 'contain',
    marginTop:width>600?'':"19rem",
    backgroundRepeat: 'no-repeat',
    height: width>600?'500px':"500px",
    width:  width>600?'500px':"200px",
   
    margin: '20px', // Adjust as needed
    
  }}>
    {/* If additional content is needed for the don.gif, it can be placed here */}
  </div>
</div>

      </div>:
       <div style={{
        backgroundImage: width>600?`url(/writebox.png)`:'',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width:'900px',
        height: '350px',
        overflowY: 'auto', // Allows vertical scrolling
        position: 'relative',
        border:  width<=600 ?'3px solid black' :"",

        padding: '1rem', // Add padding inside the container for the content
        boxSizing: 'border-box', // Include padding and border in the element's total width and height
    }}>
        {response ?
            <p style={{
              background: width<=600 ?"white":"",
              border:  width<=600 ?'3px solid black' :"",
                color: 'black', // Set text color that contrasts well with the background image
                fontFamily: 'MyCustomFont', // Your custom font
                textAlign: 'center',
                fontSize: width<=600 ? '10px' : '23px',
                padding: '0.5rem', // Adjust as needed
                margin: '0 auto', // Center horizontally and no vertical margin
                maxWidth: '80%', // Maximum width of the text block
            }}>
                {displayOutput}
                
            </p>
            :
            <textarea style={{
              width: '90%', // Adjust based on how much of the box you want the textarea to occupy
              color: 'black',
              background: width<=600 ?"white":"transparent",
              border:  width<=600 ?'3px solid black' :"",
              padding: '0.5rem',
              fontFamily: 'MyCustomFont', // Your custom font
              textAlign: 'center',
              fontSize: width<=600 ? '10px' : '23px',

              border: 'none', // Remove textarea border
              outline: 'none', // Remove textarea outline on focus
              margin: '0 auto', // Center horizontally
              display: 'block', // Ensure textarea is treated as a block element for centering
              resize: 'none', // Prevent resizing of the textarea
              overflow: 'hidden', // Allows scrolling inside the textarea if the text exceeds its bounds
              overflowY: 'hidden',
              height: '80%', // Initial height, adjust as necessary or remove to allow default height
          }}
              placeholder='Type something...'
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={handleKeyPress}
          />
          
        }
    </div>
    
      }


<div style={{display: "flex",
  flexDirection: "column",justifyContent: "center",
    
  position: "absolute", // Fixed positioning relative to the viewport
  top: 40, // Align to the bottom
  left: 20, // Align to the left
  }}>
    {
      info?
      <img src='./info-close.png'   style={{width: width>600?"50px":"30px", height: width>600?"50px":"30px"}}  onClick={()=>onClickInfo()}/>

      :<img src='./info.png'   style={{width: width>600?"50px":"30px", height: width>600?"50px":"30px"}}  onClick={()=>onClickInfo()}/>

    }
    <img src={soundStatus?'/speaker.png':"/mute.png"} style={{width: width>600?"50px":"30px", height: width>600?"50px":"30px", cursor:"pointer"}} onClick={()=>setSoundStatus(!soundStatus)}/>
    </div>

<div style={{
  
  position: "fixed", // Fixed positioning relative to the viewport
  bottom:  width<=600 ?10:0, // Align to the bottom
  left: 0, // Align to the left
  width: "100%", // Take full width of the viewport
  
  zIndex: 1000 // Ensure it's above other elements
}}>

    <div style={{display: "flex",
  flexDirection: "row",justifyContent: "center",}}>
  <img src={isRippling['Start'] ? '/start2.png' : '/start1.png'} alt="Start" style={{ width:  width>600?"200px":"80px", height:  width>600?'90px':"50px", cursor: "pointer" }} onClick={(e) => handleButtonClick('Start', e)} />
  <img src={isRippling['Cancel'] ? '/back2.png' : '/back1.png'} alt="Cancel" style={{ width:  width>600?"200px":"80px", height:  width>600?'90px':"50px", cursor: "pointer" }} onClick={(e) => handleButtonClick('Cancel', e)} />
  <img src={isRippling['Share'] ? '/share2.png' : '/share1.png'} alt="Share" style={{ width:  width>600?"200px":"80px", height:  width>600?'90px':"50px", cursor: "pointer" }} onClick={(e) => handleButtonClick('Share', e)} />
  <img src={isRippling['Donate'] ? '/yh82.png' : '/yh8.png'} alt="Donate" style={{ width:  width>600?"200px":"80px", height:  width>600?'90px':"50px", cursor: "pointer" }} onClick={(e) => handleButtonClick('Donate', e)} />
  </div>
  

  </div>

    </div>
  );
}


export default Madman;
